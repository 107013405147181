import { ApolloProvider } from '@apollo/react-hooks';
import { CloseButton } from 'components/ErrorToast';
import Loader from 'components/Loader';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import routes from 'routes';
import { client } from 'services/apollo/client';
import Auth from 'services/auth0';
import 'services/i18n';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from 'theme';

// Defer loading for pages
const HomePage = lazy(() => import('pages/Home'));
const TableGroupPage = lazy(() => import('pages/TableGroup'));
const OrderDetailPage = lazy(() => import('pages/OrderDetails'));
const OrderGroupNotePage = lazy(() => import('pages/OrderGroupNote'));
const OrderGroupDetailsPage = lazy(() => import('pages/OrderGroupDetails'));
const TableOrderPage = lazy(() => import('pages/TableOrder'));
const TableGroupHistoryPage = lazy(() => import('pages/TableGroupHistory'));
const TableHistoryPage = lazy(() => import('pages/TableHistory'));
const OrderEntryPage = lazy(() => import('pages/EntryOrder'));
const OrderSummaryPage = lazy(() => import('pages/OrderSummary'));
const LoginPage = lazy(() => import('pages/Login'));
const CallBackPage = lazy(() => import('pages/CallBack'));
const SearchGuestPage = lazy(() => import('pages/SearchGuest'));

toast.configure({
  hideProgressBar: true,
  toastClassName: 'app-toast',
  className: 'app-toast-container',
  closeButton: <CloseButton />,
});

const PrivateRoute = ({
  component: Component,
  loginPath,
  ...rest
}: {
  component: React.ComponentType;
  loginPath: string;
} & RouteProps) => (
  <Route
    {...rest}
    render={(props: any) =>
      Auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: loginPath,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export const AppContent = withRouter(({ history }: RouteComponentProps<{}>) => {
  const [token, setIsAuthentificating] = useState(null);

  useEffect(() => {
    const auth = async () => {
      try {
        const token = await Auth.silentAuth();
        if (token) {
          setIsAuthentificating(token as any);
        }
      } catch (err) {
        if (err.error !== 'login_required') console.log(err.error);
        console.error(err);
        setIsAuthentificating(null);
        history.push(routes.login());
      }
    };
    auth();
  }, [history]);

  return (
    <>
      {token && (
        <Switch>
          <PrivateRoute
            exact
            loginPath={routes.login()}
            path={routes.index()}
            component={HomePage}
          />
          <PrivateRoute
            exact
            loginPath={routes.login()}
            path={routes.tableGroup()}
            component={TableGroupPage}
          />
          <PrivateRoute
            exact
            loginPath={routes.index()}
            path={routes.searchGuests()}
            component={SearchGuestPage}
          />
          <PrivateRoute
            loginPath={routes.index()}
            path={routes.orderGroupNote()}
            component={OrderGroupNotePage}
          />
          <PrivateRoute
            exact
            loginPath={routes.index()}
            path={routes.orderDetails()}
            component={OrderDetailPage}
          />
          <PrivateRoute
            exact
            loginPath={routes.index()}
            path={routes.orderGroupDetails()}
            component={OrderGroupDetailsPage}
          />
          <PrivateRoute
            exact
            loginPath={routes.index()}
            path={routes.tableGroupHistory()}
            component={TableGroupHistoryPage}
          />
          <PrivateRoute
            exact
            loginPath={routes.index()}
            path={routes.tableHistory()}
            component={TableHistoryPage}
          />
          <PrivateRoute
            exact
            loginPath={routes.index()}
            path={routes.entryOrder()}
            component={OrderEntryPage}
          />
          <PrivateRoute
            exact
            loginPath={routes.index()}
            path={routes.orderSummary()}
            component={OrderSummaryPage}
          />
          <PrivateRoute
            loginPath={routes.login()}
            path={routes.tableOrder()}
            component={TableOrderPage}
          />
        </Switch>
      )}
      {!token && <Loader />}
    </>
  );
});

const App = () => (
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <GlobalStyle />
      <Suspense fallback={<Loader />}>
        <Router>
          <AppContent />
          <Route exact path={routes.login()} component={LoginPage} />
          <Route exact path={routes.callback()} component={CallBackPage} />
        </Router>
      </Suspense>
    </ApolloProvider>
  </ThemeProvider>
);
export default App;
