export default {
  index: () => '/',
  login: () => '/login',
  callback: () => '/callback',
  tableGroup: (
    idOffer: string = ':idOffer',
    zone: string | number = ':idZone?'
  ) => `/${idOffer}/zone/${zone}`,
  orderDetails: (idOrder = ':idOrder?') => `/order/${idOrder}`,
  orderGroupDetails: (
    idOffer: string = ':idOffer',
    idZone: string = ':idZone',
    idOrderGroup: string = ':idOrderGroup'
  ) => `/${idOffer}/${idZone}/ordergroup/${idOrderGroup}`,
  orderGroupNote: (
    idOffer: string = ':idOffer',
    idZone: string = ':idZone',
    idTable: string = ':idTable',
    idOrders: string[] = [],
  ) => `/${idOffer}/${idZone}/table/${idTable}/note${idOrders.length > 0 ? `?idOrders=${idOrders}` : ''}`,
  tableOrder: (
    idOffer: string = ':idOffer',
    idZone: string = ':idZone',
    idTable: string = ':idTable'
  ) => `/${idOffer}/${idZone}/table/${idTable}`,
  tableInProgress: (
    idOffer: string = ':idOffer',
    idZone: string = ':idZone',
    idTable: string = ':idTable'
  ) => `/${idOffer}/${idZone}/table/${idTable}/inprogress`,
  tableWaiting: (
    idOffer: string | undefined = ':idOffer',
    idZone: string | undefined = ':idZone',
    idTable: string | undefined = ':idTable'
  ) => `/${idOffer}/${idZone}/table/${idTable}/waiting`,
  searchGuests: (
    idOffer: string = ':idOffer',
    idZone: string = ':idZone',
    idTable: string = ':idTable'
  ) => `/${idOffer}/${idZone}/table/${idTable}/search`,
  tableGroupHistory: (
    idOffer: string = ':idOffer',
    idZone: string = ':idZone',
    idTable: string = ':idTable'
  ) => `/${idOffer}/${idZone}/grouphistory/${idTable}`,
  tableHistory: (idOffer: string = ':idOffer', idTable: string = ':idTable') =>
    `/${idOffer}/history/${idTable}`,
  entryOrder: (
    idOffer?: string,
    idGuest?: string,
    idZone?: string,
    idTable?: string,
    idOrder?: string
  ) =>
    `/${idOffer || ':idOffer'}/order/${idGuest || ':idGuest'}/add/${
      idZone || ':idZone'
    }/${idTable || ':idTable'}/${idTable ? idOrder || '' : ':idOrder?'}`,
  orderSummary: (
    idOrder: string = ':idOrder',
    idOffer: string = ':idOffer',
    idZone: string = ':idZone',
    idTable: string = ':idTable'
  ) => `/${idOffer}/${idZone}/table/${idTable}/orderSummary/${idOrder}`,
};
